<template>
  <div class="flex animate-pulse flex-col lg:w-72">
    <!-- Preview image -->
    <div class="h-56 bg-grey-light md:h-64 lg:h-72 lg:w-72" />
    <!-- Preview title -->
    <div class="pb-2 pt-4">
      <div class="-mt-2 h-5 w-11/12 rounded bg-grey-light xs:mt-0" />
      <div class="mt-2 h-5 w-3/4 rounded bg-grey-light" />
    </div>
  </div>
</template>
