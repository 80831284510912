import type { SanityImageObject } from '@sanity/image-url/lib/types/types';

import type { ResourcePreview } from './types';
import { getLatestPublicationDate } from '~/integrations/sanity/helpers/getLatestPublicationDate';
import { getLocaleCodes } from '~/integrations/sanity/helpers/getLocaleCodes';
import { getResourceType } from '~/integrations/sanity/helpers/getResourceType';
import { toIdSlug } from '~/lib/toIdSlug';

interface CollectionPreviewOptions {
  localeCodeToName?: (localeCode: string) => string;
}

// This interface describes the props needed to generate a `ResourcePreview`
export interface PreviewableSanityCollection {
  _type: string;
  excerpt: string;
  id: string;
  resources: {
    assets: {
      localeCode: string;
      publicationDate: string;
    }[];
  }[];
  slug: string;
  subtype?: string;
  thumbnail: SanityImageObject | null;
  title: string;
}

/**
 * @description Return an object usable in `ResourceGalleryCard` and `ResourceListCard`
 */
export function sanityCollectionToResourcePreview(
  sanityCollection: PreviewableSanityCollection,
  options: CollectionPreviewOptions = {}
): ResourcePreview {
  const { excerpt, id, slug, subtype, thumbnail, title } = sanityCollection;
  const { localeCodeToName } = options;

  const localeCodes = getLocaleCodes(sanityCollection.resources, { ignoreNoLanguage: true });

  return {
    id,
    excerpt,
    idSlug: toIdSlug(id),
    localeNames: localeCodeToName ? localeCodes.map(localeCodeToName) : localeCodes,
    publicationDate: getLatestPublicationDate(sanityCollection.resources),
    resourceType: getResourceType('collection'),
    slug,
    ...(thumbnail ? { thumbnailImageObject: thumbnail } : {}),
    ...(subtype ? { subtype } : {}),
    title,
  };
}
